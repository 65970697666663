import React, { Component } from 'react';

export default class Jumbotron extends Component {
  constructor(props) {
    super(props);

    // Add function bindings
    this.loadSpreadSheets = this.loadSpreadSheets.bind(this);
    this.addSpreadSheets = this.addSpreadSheets.bind(this);
    this.clearData = this.clearData.bind(this);
    this.login = this.login.bind(this);
    this.getSheetId = this.getSheetId.bind(this);
  }

  getSheetId() {
    const sheetId = prompt('Enter the Google Sheet ID you would like to use');
    this.props.setSheetId(sheetId);
  }

  // Click handler for load spreadsheets. Activate file input
  loadSpreadSheets() {
    this.refs.fileInput.click();
  }

  // Click handler for clear data button
  clearData() {
    this.props.clearData();
    this.refs.fileInput.value = '';
  }

  // onChange handler for file input
  addSpreadSheets() {
    this.props.onAddSpreadSheets(this.refs.fileInput.files);
  }

  // Click handler for login button
  login() {
    this.props.login();
  }

  render() {
    const loggedIn = this.props.loggedIn;
    const canClearData = this.props.canClearData;
    const sheetId = this.props.sheetId;

    return (
      <section className="jumbotron  bg-white text-center">
        <div className="container ">
          <div className="col-md-8 mx-auto">
            <h1 className="display-4">Skill Set Extractor 3000</h1>
            <p className="lead">
              Use this application to analyse your excel spreadsheets and
              extract required skill set percentages.
            </p>
            {!sheetId ? (
              <button
                className="btn btn-primary mr-2"
                onClick={this.getSheetId}
              >
                Set Google Sheet ID
              </button>
            ) : loggedIn ? (
              <div>
                <button
                  className="btn btn-primary mr-2"
                  onClick={this.loadSpreadSheets}
                >
                  Load Spreadsheets
                </button>
                <button
                  disabled={!canClearData}
                  className="btn btn-secondary"
                  onClick={this.clearData}
                >
                  Clear Data
                </button>

                <input
                  type="file"
                  hidden
                  ref="fileInput"
                  onChange={this.addSpreadSheets}
                  multiple
                />
              </div>
            ) : loggedIn !== null ? (
              <div>
                <div className="btn btn-success" onClick={this.login}>
                  Log In
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    );
  }
}
