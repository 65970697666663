// Total the skills for the provided array
export function getTotalSkillDays(totalLaboutSheetSkillDays) {
  let averageSkillsDays = {
    Applications: 0,
    Codeplugs: 0,
    Consoles: 0,
    DMR: 0,
    LTE: 0,
    Networking: 0,
    NICE: 0,
    P25: 0,
    'Problem Solving': 0,
    'Project Management': 0,
    RF: 0,
    Tetra: 0,
    Training: 0,
    Transmission: 0,
    VIDEO: 0,
    SECURITY: 0,
    'UNIFIED COMMS': 0
  };

  return totalLaboutSheetSkillDays.reduce((acc, skill) => {
    acc['Applications'] += skill['Applications'];
    acc['Codeplugs'] += skill['Codeplugs'];
    acc['Consoles'] += skill['Consoles'];
    acc['DMR'] += skill['DMR'];
    acc['LTE'] += skill['LTE'];
    acc['Networking'] += skill['Networking'];
    acc['NICE'] += skill['NICE'];
    acc['P25'] += skill['P25'];
    acc['Problem Solving'] += skill['Problem Solving'];
    acc['Project Management'] += skill['Project Management'];
    acc['RF'] += skill['RF'];
    acc['Tetra'] += skill['Tetra'];
    acc['Training'] += skill['Training'];
    acc['Transmission'] += skill['Transmission'];
    acc['VIDEO'] += skill['VIDEO'];
    acc['SECURITY'] += skill['SECURITY'];
    acc['UNIFIED COMMS'] += skill['UNIFIED COMMS'];
    return acc;
  }, averageSkillsDays);
}

// Get average skille from a single totaled spreadsheet
export function getAverageFromTotalSkillDays(
  calcSkillDays,
  length,
  summary = false
) {
  calcSkillDays['Applications'] = isNumber(
    Math.round(calcSkillDays['Applications'] / length)
  );
  calcSkillDays['Codeplugs'] = isNumber(
    Math.round(calcSkillDays['Codeplugs'] / length)
  );
  calcSkillDays['Consoles'] = isNumber(
    Math.round(calcSkillDays['Consoles'] / length)
  );
  calcSkillDays['DMR'] = isNumber(Math.round(calcSkillDays['DMR'] / length));
  calcSkillDays['LTE'] = isNumber(Math.round(calcSkillDays['LTE'] / length));
  calcSkillDays['Networking'] = isNumber(
    Math.round(calcSkillDays['Networking'] / length)
  );
  calcSkillDays['NICE'] = isNumber(Math.round(calcSkillDays['NICE'] / length));
  calcSkillDays['P25'] = isNumber(Math.round(calcSkillDays['P25'] / length));
  calcSkillDays['Problem Solving'] = isNumber(
    Math.round(calcSkillDays['Problem Solving'] / length)
  );
  calcSkillDays['Project Management'] = isNumber(
    Math.round(calcSkillDays['Project Management'] / length)
  );
  calcSkillDays['RF'] = isNumber(Math.round(calcSkillDays['RF'] / length));
  calcSkillDays['Tetra'] = isNumber(
    Math.round(calcSkillDays['Tetra'] / length)
  );
  calcSkillDays['Training'] = isNumber(
    Math.round(calcSkillDays['Training'] / length)
  );
  calcSkillDays['Transmission'] = isNumber(
    Math.round(calcSkillDays['Transmission'] / length)
  );
  calcSkillDays['VIDEO'] = isNumber(
    Math.round(calcSkillDays['VIDEO'] / length)
  );
  calcSkillDays['SECURITY'] = isNumber(
    Math.round(calcSkillDays['SECURITY'] / length)
  );
  calcSkillDays['UNIFIED COMMS'] = isNumber(
    Math.round(calcSkillDays['UNIFIED COMMS'] / length)
  );

  const total = Object.keys(calcSkillDays).reduce((acc, skill) => {
    return acc + calcSkillDays[skill];
  }, 0);
  console.log(total);
  console.log(JSON.stringify(calcSkillDays));
  if (!summary && (total >= 110 || total <= 90)) {
    throw 'Average for spreadsheet does not total to 100%. Check labour values.';
  }

  return calcSkillDays;
}

function isNumber(num) {
  if (num >= 0) {
    return num;
  } else {
    throw 'Data conversion failed while calculating percentages.';
  }
}
