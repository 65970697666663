//@ts-check

export default function() {
  var self = this;
  var STATES = new Set([
    'VIC',
    'NSW',
    'QLD',
    'SA',
    'WA',
    'NT',
    'TAS',
    'NZ',
    'Other'
  ]);

  this.importScripts(
    'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.14.1/xlsx.full.min.js'
  );

  this.addEventListener('message', function(e) {
    const file = e.data;

    analyseSpreadSheet(file)
      .then(function(res) {
        this.postMessage({
          msgState: '',
          total: res.total,
          locationState: res.locationState,
          file: file
        });
      })
      .catch(function(err) {
        this.postMessage({ msgState: 'error', errMsg: err });
      });
  });

  function analyseSpreadSheet(file) {
    // Create new file reader object
    const fileReader = new FileReader();
    const extract = new Promise(function(resolve, reject) {
      // Event triggered when a file is loaded
      fileReader.onload = e => {
        const data = e.target.result;
        // Load excel workbook into XLSX reader
        const workbook = self.XLSX.read(data, { type: 'binary' });

        // Get location state from spreadsheet
        const locationState = getSpreadsheetLocationState(workbook);

        // Get all sheets that contain 'labour' in the name
        const labourSheetNames = findLabourSheets(workbook.SheetNames);

        // Get the total skills for each sheet
        const totalLaboutSheetSkillDays = labourSheetNames.reduce(
          (acc, sheetName) => {
            if (sheetName !== 'Training Labour Est.') {
              try {
                acc.push(getSkillDaysFromSheet(workbook.Sheets[sheetName]));
              } catch (e) {
                console.log(e);
                reject('XLSX Parse error: ' + e);
              }
            }
            return acc;
          },
          []
        );

        resolve({
          total: totalLaboutSheetSkillDays,
          locationState: locationState
        });
      };
    });
    // Push file to file reader to begin process
    fileReader.readAsBinaryString(file);

    return Promise.resolve(extract);
  }

  function getSpreadsheetLocationState(workbook) {
    const sheet = workbook.Sheets['Overview'];
    try {
      const locationStateCell = sheet['B25'].v;

      if (!STATES.has(locationStateCell)) {
        return;
      }

      return locationStateCell;
    } catch (e) {
      return;
    }
  }

  function findLabourSheets(sheetNames) {
    return sheetNames.reduce((acc, sheet) => {
      if (sheet.toLowerCase().includes('labour')) {
        acc.push(sheet);
      }
      return acc;
    }, []);
  }

  function getSkillDaysFromSheet(worksheet) {
    let days = {
      total: 0.0,
      skillsDays: {
        Applications: 0.0,
        Codeplugs: 0.0,
        Consoles: 0.0,
        DMR: 0.0,
        LTE: 0.0,
        Networking: 0.0,
        NICE: 0.0,
        P25: 0.0,
        'Problem Solving': 0.0,
        'Project Management': 0.0,
        RF: 0.0,
        Tetra: 0.0,
        Training: 0.0,
        Transmission: 0,
        VIDEO: 0,
        SECURITY: 0,
        'UNIFIED COMMS': 0
      }
    };
    const keys = Object.keys(worksheet);

    // Get total number of days
    days.total = parseFloat(worksheet['D2'].v);

    if (!days.total) {
      throw 'No labour data.';
    }

    // For each labour sheet
    for (let i = 0; i < keys.length; i++) {
      const field = keys[i];

      // Only use items in column I
      if (
        field.substring(0, 1) !== 'I' ||
        isNaN(parseFloat(field.substring(1, field.length)))
      ) {
        continue;
      }

      // get the value of cell
      const cell = worksheet[keys[i]].v.trim();

      // If the celle is a title continue
      if (cell.includes('Engineer Skillset')) {
        continue;
      }

      // Get the line number from the cell
      const lineNumber = field.substring(1, field.length);

      // if cell in column D is blank or is 0, continue
      if (
        !worksheet['D' + lineNumber.toString()] ||
        worksheet['D' + lineNumber.toString()] === 0
      ) {
        continue;
      }

      // If cell inlcudes project management, remove its day count from total
      if (cell.includes('Project Management')) {
        days.total -= parseFloat(worksheet['D' + lineNumber.toString()].v);

        continue;
      }

      // If cell inlcudes admin, remove its day count from total
      if (cell.includes('Admin')) {
        days.total -= parseFloat(worksheet['D' + lineNumber.toString()].v);

        continue;
      }

      //Check if total is above 0
      if (!days.total) {
        throw 'No labour data.';
      }

      // Get day cound from column D and add to skill object
      days.skillsDays[cell] += parseFloat(
        worksheet['D' + lineNumber.toString()].v
      );
    }
    console.log(JSON.stringify(days));
    // Convert total days to percentage and return
    return convertDaysToPercentage(days);
  }

  function convertDaysToPercentage(days) {
    return {
      Applications: Math.round(
        (days.skillsDays['Applications'] / days.total) * 100
      ),
      Codeplugs: Math.round((days.skillsDays['Codeplugs'] / days.total) * 100),
      Consoles: Math.round((days.skillsDays['Consoles'] / days.total) * 100),
      DMR: Math.round((days.skillsDays['DMR'] / days.total) * 100),
      LTE: Math.round((days.skillsDays['LTE'] / days.total) * 100),
      Networking: Math.round(
        (days.skillsDays['Networking'] / days.total) * 100
      ),
      NICE: Math.round((days.skillsDays['NICE'] / days.total) * 100),
      P25: Math.round((days.skillsDays['P25'] / days.total) * 100),
      'Problem Solving': Math.round(
        (days.skillsDays['Problem Solving'] / days.total) * 100
      ),
      'Project Management': Math.round(
        (days.skillsDays['Project Management'] / days.total) * 100
      ),
      RF: Math.round((days.skillsDays['RF'] / days.total) * 100),
      Tetra: Math.round((days.skillsDays['Tetra'] / days.total) * 100),
      Training: Math.round((days.skillsDays['Training'] / days.total) * 100),
      Transmission: Math.round(
        (days.skillsDays['Transmission'] / days.total) * 100
      ),
      VIDEO: Math.round((days.skillsDays['VIDEO'] / days.total) * 100),
      SECURITY: Math.round((days.skillsDays['SECURITY'] / days.total) * 100),
      'UNIFIED COMMS': Math.round(
        (days.skillsDays['UNIFIED COMMS'] / days.total) * 100
      )
    };
  }
}
