// @ts-check

import React, { Component } from 'react';

import Nav from './components/Nav';
import Jumbotron from './components/Jumbotron';
import Card from './components/Card';
import {
  getTotalSkillDays,
  getAverageFromTotalSkillDays
} from './components/functions/utility';

const CLIENTID =
  '219063836346-maph036u3efcr9lms1emdq9b2h0thrfa.apps.googleusercontent.com';
const APIKEY = 'AIzaSyCn1aIHNSE2qGmJLj3mcJWjBjg6JIb2KIs';

export default class App extends Component {
  constructor(props) {
    super(props);

    // Initialise state
    this.state = {
      sheetId: null,
      ready: true,
      cards: [],
      summary: this.getDefaultSummary(),
      loggedIn: null,
      // selectedState: 'State...',//FIXME:
      saveInProgress: false
    };

    // Set up function bindings
    this.handleLoadSpreadSheets = this.handleLoadSpreadSheets.bind(this);
    this.clearData = this.clearData.bind(this);
    this.login = this.login.bind(this);
    this.updateSigninStatus = this.updateSigninStatus.bind(this);
    this.logOut = this.logOut.bind(this);
    this.submitSkills = this.submitSkills.bind(this);
    this.addProcessedCard = this.addProcessedCard.bind(this);
    this.removeCard = this.removeCard.bind(this);
    this.changeCardLocation = this.changeCardLocation.bind(this);
    this.setSheetId = this.setSheetId.bind(this);
    this.removeSheetId = this.removeSheetId.bind(this);
  }

  componentDidMount() {
    // Initialise google api login
    window.gapi.load('client:auth2', () => {
      window.gapi.client
        .init({
          apiKey: APIKEY,
          discoveryDocs: [
            'https://sheets.googleapis.com/$discovery/rest?version=v4'
          ],
          clientId: CLIENTID,
          scope: 'https://www.googleapis.com/auth/drive'
        })
        .then(() => {
          // Listen for sign-in state changes.
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(this.updateSigninStatus);

          // Handle the initial sign-in state.
          this.updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          );
        });
    });

    const sheetId = localStorage.getItem('sheetId');
    if (sheetId) {
      this.setState({
        sheetId: sheetId
      });
    }
  }

  render() {
    const ready = this.state.ready;
    const cards = this.state.cards;
    const summary = this.state.summary;
    const loggedIn = this.state.loggedIn;
    const sheetId = this.state.sheetId;

    const saveInProgress = this.state.saveInProgress;
    const canClearData = cards.length > 0;

    return (
      <div>
        <Nav
          sheetId={sheetId}
          removeSheetId={this.removeSheetId}
          logOut={this.logOut}
          loggedIn={loggedIn}
        />
        <Jumbotron
          ready={ready}
          onAddSpreadSheets={this.handleLoadSpreadSheets}
          clearData={this.clearData}
          loggedIn={loggedIn}
          login={this.login}
          setSheetId={this.setSheetId}
          sheetId={sheetId}
          canClearData={canClearData}
        />

        {ready && cards.length > 0 && (
          <div className="py-5 bg-light">
            <div className="container">
              <div className="row">
                {cards.map((card, i) => {
                  return (
                    <Card
                      addProcessedCard={this.addProcessedCard}
                      file={card.file}
                      index={i}
                      key={card.file.name}
                      removeCard={this.removeCard}
                      changeLocation={this.changeCardLocation}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {summary.ready && (
          <div className="py-5">
            <div className="container">
              <div className="row d-flex justify-content-center">
                {summary.locations.map(location => {
                  if (location.ready) {
                    return <Card key={location.quoteName} summary={location} />;
                  }
                  return '';
                })}
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col">
                  <button
                    onClick={this.submitSkills}
                    className="btn btn-block btn-success btn-block"
                    disabled={saveInProgress}
                  >
                    Submit to Resource Planner
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  removeSheetId() {
    localStorage.removeItem('sheetId');
    this.setState({ sheetId: null });
  }

  setSheetId(sheetId) {
    localStorage.setItem('sheetId', sheetId);
    this.setState({ sheetId: sheetId });
  }

  changeCardLocation(res) {
    let cards = this.state.cards;

    cards[res.index].locationState = res.location;
    this.setState({ cards: cards }, () => {
      this.evalSummaryReady();
    });
  }

  removeCard(index) {
    let cards = this.state.cards;
    cards.splice(index, 1);

    this.setState({ cards: cards, summary: this.getDefaultSummary() }, () => {
      this.evalSummaryReady();
    });
  }

  addProcessedCard(card) {
    this.setState(
      previousState => {
        previousState.cards[card.index] = card;
        return previousState;
      },
      () => {
        this.evalSummaryReady();
      }
    );
  }

  evalSummaryReady() {
    const cards = this.state.cards;

    for (let i = 0; i < cards.length; i++) {
      if (
        cards[i].locationState === 'State...' ||
        (cards[i].activeState !== 'ANALYSED' &&
          cards[i].activeState !== 'ERROR')
      ) {
        // Card has not completed analysing
        this.setState(previousState => {
          previousState.summary.ready = false;

          return previousState;
        });
        return;
      }
    }

    // If all sheets have been analysed, create the summary

    this.calcSummary();
  }

  // Push skills to google sheet
  async submitSkills() {
    const locations = this.state.summary.locations;

    let summaries = [];

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].ready) {
        console.log(locations[i].location);
        summaries.push(
          this.calcSummariesInGoogleSheet(
            locations[i].skills,
            locations[i].location,
            locations[i].quotesCount
          )
        );
      }
    }

    await Promise.all(summaries);

    this.clearData();

    alert('Data successfully saved.');
  }

  async calcSummariesInGoogleSheet(skills, state, quoteCount) {
    const sheetId = this.state.sheetId;
    // Extract skills from object to prep for request
    const skillArray = this.skillsToArray(skills, quoteCount);
    try {
      // Params for google sheets request
      const postParams = {
        spreadsheetId: sheetId,
        range: `${state}!A80:80`,
        valueInputOption: 'RAW'
      };

      // Data to be sent on google sheets request
      const payload = {
        range: `${state}!A80:80`,
        majorDimension: 'ROWS',
        values: skillArray
      };

      // Update UI
      this.setState({
        saveInProgress: true
      });

      // Make the google sheets request
      await window.gapi.client.sheets.spreadsheets.values.append(
        postParams,
        payload
      );

      // Params for google sheets request
      const getParams = {
        spreadsheetId: sheetId,
        ranges: [`${state}!A80:P`],
        majorDimension: 'ROWS'
      };

      const response = await window.gapi.client.sheets.spreadsheets.values.batchGet(
        getParams
      );

      const data = response.result.valueRanges[0].values;

      let total = {
        quoteCount: 0,
        skills: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      };

      for (let i = 0; i < data.length; i++) {
        let totalSkill = [];

        for (let k = 2; k < data[i].length; k++) {
          const skills =
            total.skills[k - 2] * total.quoteCount + data[i][k] * data[i][1];
          totalSkill.push(skills / (total.quoteCount + parseInt(data[i][1])));
        }

        for (let m = 0; m < totalSkill.length; m++) {
          total.skills[m] = totalSkill[m];
        }
        total.quoteCount += parseInt(data[i][1]);
      }

      // Params for google sheets request
      const updateParams = {
        spreadsheetId: sheetId,
        range: `${state}!B41:B57`,
        valueInputOption: 'RAW'
      };

      // Data to be sent on google sheets request
      const updatePayload = {
        range: `${state}!B41:B57`,
        majorDimension: 'COLUMNS',
        values: [total.skills]
      };

      await window.gapi.client.sheets.spreadsheets.values.update(
        updateParams,
        updatePayload
      );
    } catch (error) {
      console.error('error: ' + JSON.stringify(error));

      alert('An error occured. Try again...');

      this.setState({
        saveInProgress: false
      });
    }
  }

  // Extract skills form object to array to prep for request to google sheets
  skillsToArray(skills, count) {
    // Get the keys from the object
    const keys = Object.keys(skills).map(skill => {
      return skill;
    });

    // Loop through each of the keys and return the skill
    const values = keys.map(skill => {
      return skills[skill];
    });

    // Return keys and values as array of arrays
    // return [keys, values];
    return [[new Date().toISOString(), count, ...values]];
  }

  // Begin login to google services
  login() {
    window.gapi.auth2.getAuthInstance().signIn();
  }

  // Log out of google services
  logOut() {
    window.gapi.auth2.getAuthInstance().signOut();
    this.clearData();
  }

  // Update state on sign in
  updateSigninStatus(isSignedIn) {
    this.setState({
      loggedIn: isSignedIn
    });
  }

  // Reset form
  clearData() {
    this.setState({
      summary: this.getDefaultSummary(),
      cards: [],
      saveInProgress: false,
      selectedState: 'State...' // FIXME:
    });
  }

  // Button click handler for adding new spreadsheet
  // Begins the analysing process
  handleLoadSpreadSheets(files) {
    let structuredFiles = [];

    // Create new object for each file imported //TODO: Update docs
    for (let i = 0; i < files.length; i++) {
      if (!this.checkDuplicates(files[i])) {
        const tempCard = {
          file: files[i]
        };

        structuredFiles.push(tempCard);
      }
    }
    // Update state to include new files and disable summary while analysing is in progress
    this.setState(state => {
      return {
        cards: [...state.cards, ...structuredFiles],
        summary: this.getDefaultSummary()
      };
    });
  }

  // Check if a spreadsheet has already been loaded into the page
  checkDuplicates(file) {
    const cards = this.state.cards;

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].file.name === file.name) {
        alert(
          `The ${cards[i].file.quoteName} spreadsheet has already been added. Delete it first to re-add it.`
        );
        return true;
      }
    }

    return false;
  }

  // Create/Update the summary of all spreadsheets loaded into the page
  calcSummary() {
    const cards = this.state.cards;
    let locations = this.state.summary.locations;

    for (let i = 0; i < locations.length; i++) {
      // Total all spreadsheets that have already been analysed
      const totalCards = this.getAllCardsSkillsDays(
        cards,
        locations[i].location
      );
      // Average the total
      let totalCardsAverage;
      try {
        if (totalCards.count === 0) continue;

        totalCardsAverage = getAverageFromTotalSkillDays(
          totalCards.skills,
          totalCards.count,
          true
        );
      } catch (e) {
        console.error('error caught:::', e);
      }

      locations[i].ready = totalCards.count > 0 ? true : false;
      locations[i].skills = totalCardsAverage;
      locations[i].quotesCount = totalCards.count;
      // Update state and display summary
    }

    let showSummary = false;

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].quotesCount > 0) {
        showSummary = true;
        break;
      }
    }

    this.setState(previousState => {
      previousState.summary.locations = locations;
      previousState.summary.ready = showSummary;

      return previousState;
    });
  }

  // Reduce skills array out of card object and get total of skills
  getAllCardsSkillsDays(cards, location) {
    let cardSkills = cards.reduce((acc, card) => {
      if (card.activeState === 'ANALYSED' && card.locationState === location) {
        acc.push(card.skills);
      }
      return acc;
    }, []);

    return { skills: getTotalSkillDays(cardSkills), count: cardSkills.length };
  }

  getDefaultSummary() {
    return {
      ready: false,
      locations: [
        {
          location: 'VIC',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'VIC Summary',
          activeState: 'ANALYSED'
        },
        {
          location: 'NSW',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'NSW Summary',
          activeState: 'ANALYSED'
        },
        {
          location: 'QLD',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'QLD Summary',
          activeState: 'ANALYSED'
        },
        {
          location: 'SA',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'SA Summary',
          activeState: 'ANALYSED'
        },
        {
          location: 'WA',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'WA Summary',
          activeState: 'ANALYSED'
        },
        {
          location: 'NZ',
          ready: false,
          skills: null,
          quotesCount: 0,
          quoteName: 'NZ Summary',
          activeState: 'ANALYSED'
        }
      ]
    };
  }
}
