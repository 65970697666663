import React, { Component } from 'react';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.logOut = this.logOut.bind(this);
    this.removeSheetId = this.removeSheetId.bind(this);
  }

  render() {
    const loggedIn = this.props.loggedIn;
    const sheetId = this.props.sheetId;

    return (
      <div className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <span className="navbar-brand mr-auto">Quote Analyser</span>
          {sheetId && (
            <button
              className="btn btn-outline-secondary"
              onClick={this.removeSheetId}
            >
              Set Google Sheet ID
            </button>
          )}
          {loggedIn && (
            <button className="btn btn-outline-secondary" onClick={this.logOut}>
              Log Out
            </button>
          )}
        </div>
      </div>
    );
  }

  // Click handler for log out button
  logOut() {
    this.props.logOut();
  }

  removeSheetId() {
    this.props.removeSheetId();
  }
}

export default Nav;
