import React, { Component } from 'react';

import worker from './WebWorkers/sheetAnalyser.worker';
import WebWorker from './WebWorkers/WebWorker';
import {
  getTotalSkillDays,
  getAverageFromTotalSkillDays
} from './functions/utility';

export default class Card extends Component {
  constructor(props) {
    super(props);

    if (this.props.summary) {
      // set card state for summary
      this.state = {
        file: null,
        quoteName: this.props.summary.quoteName,
        activeState: 'ANALYSED',
        locationState: 'State...',
        skills: this.props.summary.skills
      };
    } else {
      // set card state for new file
      this.state = {
        file: this.props.file,
        quoteName: this.props.file.name,
        activeState: 'LOADED',
        locationState: 'State...'
      };
      this.worker = null;
    }

    this.locationStateChanged = this.locationStateChanged.bind(this);
    this.removeCard = this.removeCard.bind(this);
  }

  componentDidMount() {
    if (this.props.summary) {
      return;
    }

    this.worker = new WebWorker(worker);

    this.worker.addEventListener('message', e => {
      try {
        if (e.data.msgState === 'error') {
          throw e.data.errMsg;
        }

        const result = e.data.total;
        const locationState = e.data.locationState;
        let totalSkillDays;

        totalSkillDays = getTotalSkillDays(result);

        const averageSkillDays = getAverageFromTotalSkillDays(
          totalSkillDays,
          result.length
        );

        this.setState(
          previousState => {
            previousState.skills = averageSkillDays;
            previousState.activeState = 'ANALYSED';
            previousState.locationState = locationState
              ? locationState
              : previousState.locationState;

            return previousState;
          },
          () => {
            this.props.addProcessedCard({
              skills: this.state.skills,
              locationState: this.state.locationState,
              activeState: this.state.activeState,
              index: this.props.index,
              file: this.state.file
            });
          }
        );
      } catch (message) {
        this.setState(
          previousState => {
            previousState.activeState = 'ERROR';
            previousState.errorMessage = message;
            return previousState;
          },
          () => {
            this.props.addProcessedCard({
              skills: this.state.skills,
              locationState: 'None',
              activeState: this.state.activeState,
              index: this.props.index,
              file: this.state.file
            });
          }
        );
      }

      this.worker.terminate();
    });

    this.worker.postMessage(this.state.file);

    this.setState({
      activeState: 'ANALYSING'
    });
  }

  renderState() {
    const activeState = this.state.activeState;
    const skills = this.state.skills;
    const errMsg = this.state.errorMessage || '';

    // Determin what to show on each card determined by state
    switch (activeState) {
      case 'LOADED':
        return <p>Sheet Loaded...</p>;
      case 'ANALYSING':
        return <p>Analysing...</p>;
      case 'ERROR':
        return (
          <div>
            <p>
              An error occured. Ensure excel spreadsheet is formatted correctly.
              This spreadsheet will <b>not</b> be included in final results.
            </p>
            <p>
              <b>{errMsg}</b>
            </p>
          </div>
        );
      case 'ANALYSED':
        return (
          <ul>
            {Object.keys(skills).map((skill, i) => {
              return (
                <li key={i}>
                  <b>{skill}</b>: {skills[skill]}% <br />
                </li>
              );
            })}
          </ul>
        );
      default:
        return <p>Internal state error</p>;
    }
  }

  render() {
    const quoteName = this.state.quoteName;
    const locationState = this.state.locationState;
    const activeState = this.state.activeState;
    let statusClass;

    switch (this.state.activeState) {
      case 'ERROR':
        statusClass = 'bg-danger';
        break;
      case 'ANALYSED':
        if (this.state.locationState === 'State...') {
          statusClass = 'bg-primary';
        } else {
          statusClass = 'bg-success';
        }

        break;
      default:
        statusClass = '';
    }

    return (
      <div className="col-md-4">
        <div className="card mb-4 ">
          <h5 className={'card-header ' + statusClass}>{quoteName}</h5>
          <div className="card-body">
            <div className="card-text">{this.renderState()}</div>
            {(activeState === 'ANALYSED' || activeState === 'ERROR') &&
              !this.props.summary && (
                <div className="row">
                  <div className="col">
                    <button
                      onClick={this.removeCard}
                      className="btn btn-outline-danger"
                    >
                      Remove
                    </button>
                  </div>
                  <div className="col">
                    <select
                      onChange={this.locationStateChanged}
                      value={locationState}
                      className="form-control"
                      disabled={activeState === 'ERROR'}
                    >
                      <option disabled>State...</option>
                      <option value="VIC">VIC</option>
                      <option value="NSW">NSW</option>
                      <option value="QLD">QLD</option>
                      <option value="SA">SA</option>
                      <option value="WA">WA</option>
                      <option value="NZ">NZ</option>
                    </select>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }

  locationStateChanged(e) {
    this.setState({
      locationState: e.target.value
    });

    this.props.changeLocation({
      location: e.target.value,
      index: this.props.index
    });
  }

  removeCard() {
    this.props.removeCard(this.props.index);
  }
}
